import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
export const SEO = ({ title }: { title?: string }) => {
  const { site } = useStaticQuery(query);
  const { defaultTitle, titleTemplate } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
  };
  return <Helmet title={seo.title} titleTemplate={titleTemplate} />;
};

SEO.propTypes = {
  title: PropTypes.string,
};
SEO.defaultProps = {
  title: null,
};

export enum PageTitle {
  FourOhFour = "404",
  AboutUs = "about us",
  Blog = "blog",
  BlogSingle = "blog single",
  Cart = "cart",
  CheckOut = "check out",
  Contact = "contact",
  ForgotPassword = "forgot password",
  HistoryPage = "history",
  Login = "login",
  MeetYourShroomSingle = "meet your shroom single",
  MeetYourShrooms = "meet your shrooms",
  Products = "products",
  Profile = "profile",
  Welcome = "welcome",
  Home = "troop",
}

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
